<template>
    <div class="toolList">
        <div>
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click="toCreate(1)" type="primary" icon="plus" >新建活码</a-button>
                        </a-form-item>
                        <!-- <a-form-item>
                            <a-button @click="toCreate(2)" type="primary" icon="plus" >批量新建活码</a-button>
                        </a-form-item> -->
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-input v-model='searchParams.search.name'  style="width: 160px" allowClear placeholder="请输入活码名称"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchList" html-type="submit" type="primary" >搜索</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table :pagination="false" :bordered='false' rowKey="id" size="middle"
                    :columns="columns" :dataSource="list" @change="handleChange">
                    <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="content" slot-scope="text, record , index">
                    <div class="talkingSkills-list-content">
                        <div v-if="record.discourse_type == 'text'">{{text}}</div>
                        <div class="talkingSkills-list-content-img" v-if="record.discourse_type == 'image'">
                        <img :preview='index' :src="text" alt="">
                        <div>{{record.ext_name}}</div>
                        </div>
                        <div class="talkingSkills-list-content-video" v-if="record.discourse_type == 'video'">
                        <a :href="text" target="_blank">
                            <a-icon class="talkingSkills-list-content-video-icon" type="play-circle" theme="twoTone" />
                            <img :src="`${text}?vframe/jpg/offset/8/h/60`" alt="">
                        </a>
                        <div>{{record.ext_name}}</div>
                        </div>
                        <div v-if="record.discourse_type == 'file'" class="talkingSkills-list-content-img">
                        <a-icon type="file" two-tone-color="#00cca2" theme="twoTone" :style="{fontSize:'36px'}" />
                        <div>{{record.ext_name}}</div>
                        </div>
                    </div>
                    </template>
                    <template slot="action" slot-scope="text,record">
                    <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
                    <a-divider v-if="canUpdate" type="vertical" />
                    <a v-if="canDelete" href="javascript:;" @click="deleteItem(record)">删除</a>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '二维码', dataIndex: 'discourse_name', key: '9',width:'200px',ellipsis:true},
  { title: '名称', dataIndex: 'discourse_content', key: '1',scopedSlots: { customRender: 'content' }},
  { title: '扫码添加次数', dataIndex: 'used_count', key: 'used_count',align:'center',width:'100px',sorter:()=>{}},
  { title: '使用成员', dataIndex: 'discourse_type_name',ellipsis:true,align:'center',width:'100px'},
  { title: '备用成员', dataIndex: 'created_by',ellipsis:true,align:'center',width:'100px'},
  { title: '标签', dataIndex: 'created_at',ellipsis:true,align:'right',width:'200px',sorter:()=>{}},
  { title: '创建时间', dataIndex: 'created_ats',ellipsis:true,align:'right',width:'200px',sorter:()=>{}},
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' },width:'100px'}
]

    import tableMixins from '@/common/mixins/table'
    export default {
        name:'list',
        data() {
            return {
                columns,
                loading:false,
                list: [],
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        mixins: [ tableMixins ],
        created () {

        },
        methods: {
            getList(){},
            toCreate(num){
                this.$router.push({path:'/market/tool/create',query:{isTab:num}})
            }
        },
    }
</script>

<style lang="scss" scoped>
    .toolList{

    }
</style>