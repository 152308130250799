<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>渠道活码</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">渠道列表<a-badge :count="0" /></span>
          <list v-if="activeKey==='1'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="2">
          <span slot="tab">渠道详情<a-badge :count="0" /></span>
          <detail v-if="activeKey==='2'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import list from './list'
import detail from './detail'
export default {
  components: {
    list,
    detail,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

